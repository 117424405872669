import { Link } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";

const StaticPage = ({ pageContext }) => {
  return (
    <Layout
      menu={pageContext.menu}
      footerMenu={pageContext.footerMenu}
      title="Dziękujemy za wiadomość - - Posadzki Przemysłowe Polska">
      <div className="bg-gradient">
        <Header lang={pageContext.lang} />
        <Breadcrumbs data={pageContext.breadcrumbs} lang={pageContext.lang} />
        <div className="main-wrapper-md">
          <div className="static-page-container">
            <header className="section-header red-line">
              <h2>Dziękujemy za wysłanie wiadomości</h2>
            </header>
            <p>Skontaktujemy się z Tobą wkrótce.</p>
            <Link to="/pl" className="btn-red">
              <span>
                <strong>Strona główna</strong>
              </span>
              <img
                src={require("../images/arrow-button.svg")}
                style={{ width: "23px" }}
                alt="white arrow"
              />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StaticPage;
